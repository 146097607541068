@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;0,600;0,700;1,400&display=swap");
:root {
  --primary-color: #a862ff;
  --secondary-color: #01ebba;
  --warning-color: #e5a400;
  --dark-color: #0c0033;
  --light-color: #f5f8fd;
  --white-color: #fff;
  --primary-font: "Poppins", sans-serif;
  --border-radius-small: 0.875rem;
  --border-radius-large: 1.125rem;
  --border-color: #cacaca;
  --placeholder: rgba(12, 0, 51, 0.6);
}
.studentNew a {
  text-decoration: none;
}

.studentNew {
  font-family: "Poppins", sans-serif;
  color: var(--dark-color);
  /* background-color: var(--light-color); */
}

/* .studentNew * {
  z-index: 1000;
} */
/* .studentNew::before {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  background-image: url(../assets/images/BG.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
} */


#root::before {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  background-image: url(../assets/images/BG.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}


.studentNew .navbar {
  border: none;
  padding: 0.5rem 1rem;
}
.studentNew .navbar-brand img {
  max-height: none;
}
.studentNew h1,
.studentNew .h1 {
  font-size: clamp(1.5rem, 8vw, 2.5rem);
}
.studentNew footer {
  background-color: var(--dark-color);
}
.studentNew .for-all-ages i {
  color: var(--primary-color);
  padding-bottom: 0.75rem;
  position: relative;
  display: inline-block;
}
.studentNew .for-all-ages i img {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
.studentNew .form-sec {
  border-radius: 1.25rem !important;
}
.studentNew .form-sec a {
  color: var(--primary-color);
}
.studentNew .video-thumbnail {
  border-radius: 5px;
  aspect-ratio: 16 / 9;
}
.studentNew .video-player {
  width: 100% !important;
  height: unset !important;
  aspect-ratio: 16 / 9;
}
.studentNew .video-player video {
  height: 100%;
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 5px;
}
.studentNew .video-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(168, 98, 255);
  font-size: 60px;
  background-color: rgb(243, 237, 237);
  border-radius: 50%;
  padding: 0;
  /* z-index: 10; */
}
.studentNew h1,
.studentNew h3 {
  word-spacing: -1px;
}
.studentNew .interactive,
.studentNew .lifetime,
.studentNew .flexible {
  position: relative;
}
.studentNew .interactive::before,
.studentNew .lifetime::before,
.studentNew .flexible::before {
  content: "";
  position: absolute;
  inset: 0;
  filter: opacity(25%);
  border-radius: var(--border-radius-small);
}
.studentNew .interactive::before {
  background-color: var(--warning-color);
}
.studentNew .lifetime::before {
  background-color: var(--primary-color);
}
.studentNew .flexible::before {
  background-color: var(--secondary-color);
}
.studentNew .card-sec {
  position: relative;
  height: 200px;
}
.studentNew .card-sec::before {
  content: url(../assets/images/quote.png);
  position: absolute;
  left: 10px;
  top: 0;
}
.studentNew .review-sec .card-sec {
  border-radius: var(--border-radius-small);
}
.studentNew .review-sec .card-sec p {
  font-size: 0.875rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
}
@media (max-width: 768px) {
  .studentNew .card-sec {
    position: relative;
    height: 160px;
  }
  .studentNew .review-sec .card-sec p {
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
  }
}
.studentNew .review-sec .card-sec .star svg {
  margin-right: -2px;
}
.studentNew .btn {
  font-size: 0.875rem;
}
.studentNew .btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.studentNew .btn-primary:hover,
.studentNew .btn-primary:focus,
.studentNew .btn-primary:active {
  background-color: var(--dark-color) !important;
  border-color: var(--dark-color) !important;
}
.studentNew .btn-secondary {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}
.studentNew .btn-secondary:hover,
.studentNew .btn-secondary:focus,
.studentNew .btn-secondary:active {
  background-color: var(--dark-color) !important;
  border-color: var(--dark-color) !important;
}
.studentNew .btn-warning {
  background-color: var(--warning-color) !important;
  border-color: var(--warning-color) !important;
  color: var(--white-color) !important;
}
.studentNew .btn-warning:hover,
.studentNew .btn-warning:focus,
.studentNew .btn-warning:active {
  background-color: var(--dark-color) !important;
  border-color: var(--dark-color) !important;
  color: var(--white-color) !important;
}
.studentNew .form-control {
  color: var(--dark-color) !important;
}
.studentNew .phone-sec button {
  min-width: 107px;
  line-height: 1.5rem;
}
.studentNew .phone-sec select {
  border-radius: 0.25rem 0 0 0.25rem;
}
.studentNew .form-sec label {
  font-weight: 500;
}
.studentNew .custom-checkbox label {
  font-weight: normal;
}
.studentNew .cxi-arrow-right {
  fill: var(--primary-color);
}
.studentNew .sign-up-news {
  background: white;
  border: 0;
  border-radius: 0 0.25rem 0.25rem 0;
}
.studentNew .text-primary {
  color: var(--primary-color) !important;
}
.studentNew footer li {
  font-size: 0.875rem;
}
.studentNew footer li a {
  transition: all 0.2s;
}
.studentNew footer li a:hover {
  color: var(--primary-color) !important;
}
.studentNew footer .btn-primary {
  transition: all 0.3s;
}
.studentNew footer .btn-primary:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.studentNew .react-tel-input {
  width: 100%;
}
.studentNew .react-tel-input input {
  width: 100%;
}
.resend-otp {
  color: var(--primary-color);
  cursor: pointer;
}
.studentNew option[disabled] {
  color: var(--secondary-color);
}
.form-control {
  border-color: var(--border-color);
}
.form-control:focus {
  border-color: var(--primary-color);
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}
.studentNew .react-tel-input .form-control {
  padding: 0.375rem 60px;
}
.react-tel-input .form-control:focus {
  background-color: #fff;
  border-color: var(--primary-color) !important;
  outline: 0 !important;
  box-shadow: none !important;
}
::placeholder {
  color: var(--placeholder) !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--placeholder) !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--placeholder) !important;
}
